// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arakatu-js": () => import("./../../../src/pages/arakatu.js" /* webpackChunkName: "component---src-pages-arakatu-js" */),
  "component---src-pages-azken-artikuluak-js": () => import("./../../../src/pages/azken-artikuluak.js" /* webpackChunkName: "component---src-pages-azken-artikuluak-js" */),
  "component---src-pages-bilatu-js": () => import("./../../../src/pages/bilatu.js" /* webpackChunkName: "component---src-pages-bilatu-js" */),
  "component---src-pages-hiztegia-js": () => import("./../../../src/pages/hiztegia.js" /* webpackChunkName: "component---src-pages-hiztegia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nor-garen-js": () => import("./../../../src/pages/nor-garen.js" /* webpackChunkName: "component---src-pages-nor-garen-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-kategoria-js": () => import("./../../../src/templates/kategoria.js" /* webpackChunkName: "component---src-templates-kategoria-js" */)
}

