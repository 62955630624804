module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"timeout":120000,"perPage":85,"requestConcurrency":4,"previewRequestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":false,"html":{"useGatsbyImage":false,"createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"generateWebpImages":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":100000,"hardCacheData":false},"production":{"hardCacheMediaFiles":true,"allow404Images":false},"type":{"Post":{"limit":9999999},"Adiera":{"limit":9999999},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"url":"https://gizapedia.hirusta.io/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-88015690-1","cookieName":"gizapedia.org","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"699b2f817d7f2a48a5f5c9bc9be5ada1","pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gizapedia","short_name":"Gizapedia","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
