// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
import './src/styles/global.css';

export const onRouteUpdate = (event) => {
    if (window.posthog) {
        window.posthog.capture('$pageview')
    }
}